import React  from "react"
import Layout from "../../components/layout";
import { Helmet } from "react-helmet"; 
import {  SunIcon } from "@heroicons/react/solid"; 
import { StaticImage } from "gatsby-plugin-image"; 
import { Link } from "gatsby";
import { Flats } from "../../components/Flats";

const Trio01Page = ({location}) => {
     
  // Move this to it's own component consuming context.
 

  return (
  <>
       <Helmet>
      <meta charSet="utf-8" />
      <title>Trio 1 | Domy Trio</title> 
    </Helmet>
      <Layout location={location}> 
   

      
<main >
    <div className="mx-auto w-full   relative z-30 ">
       <div className="mx-auto max-w-6xl w-full p-8 lg:px-14 z-auto  backdrop-filter  bg-white bg-opacity-90 relative shadow-md border border-gray-200 ">    
             <svg className="absolute w-60 h-60 text-yellow-400 top-0 right-0 ">
                <polygon points="0,0 300,0 300,300" class="triangle" fill="currentColor" />
                <SunIcon width="50" x="160" y="-70"  fill="#ffffff" />
            </svg>
            <div className="relative">
           <div className="">
               <h1 className="text-xl lg:text-3xl font-merri mb-10 border-b border-yellow-400 pb-3">Trio 1</h1> 
           </div>
            
                 <Flats trio={1} />
 

                   
        </div>
    </div>

    </div>
  </main>
    <div >
        
  </div>
    </Layout>
  </>
  )
}

export default Trio01Page
 